<template>
  <section v-if="data" class="o-breakdowns-default">
    <div class="o-breakdowns-default__container container">
      <div class="o-breakdowns-default__left-column">
        <h2 class="o-breakdowns-default__title">{{ data.title }}</h2>

        <h3 class="o-breakdowns-default__subtitle">{{ data.subtitle }}</h3>

        <Button
          v-if="data.button.type === 'dialog'"
          theme="light"
          modifier="tertiary"
          @click="requireForm(formList[data.button.value])"
        >
          {{ data.button.title }}
        </Button>
      </div>

      <div class="o-breakdowns-default__right-column">
        <div class="o-breakdowns-default__cards">
          <Card
            v-for="(breakdown, i) in data.breakdowns"
            :key="i"
            :href="breakdown.link"
            :title="breakdown.title"
            css-class="m-card-breakdown"
            tag="a"
          >
            <template #contentHeader>{{ breakdown.title }}</template>

            <template #content>
              <div class="m-card-breakdown__content-row">
                <span>Признак: </span>
                {{ breakdown.symptom }}
              </div>

              <div class="m-card-breakdown__content-row">
                <span>Причина: </span>
                {{ breakdown.cause }}
              </div>

              <div v-if="breakdown.price" class="m-card-breakdown__content-row">
                <span>Цена ремонта: </span>
                от {{ breakdown.price }} ₽
              </div>
            </template>
          </Card>
        </div>

        <div v-if="data.hub" class="o-breakdowns-default__all">
          <Button theme="light" modifier="tertiary" tag="a" :href="data.hub.url">
            {{ data.hub.title }}
          </Button>
        </div>
      </div>
    </div>
  </section>

  <section v-else-if="!websiteConfig.modes.isProduction">
    <UtilsDataMissing :data="props.data" />
  </section>
</template>

<script setup lang="ts">
// Vue
import { ref } from 'vue';

// Components
import UtilsDataMissing from '~/components/Utils/Data/Missing/UtilsDataMissing.vue';
import Card from '@ice-products-ui/vue-library/Card';
import Button from '@ice-products-ui/vue-library/Button';

// Composables
import useGetData from '~/composables/api/useGetData';
import useComponentSchemaValidator from '~/composables/validators/useComponentSchemaValidator';
import useForms from '~/composables/molecules/useForms';

// Types
import type { IComponentProps } from '~/typings/types/component.types';
import type { TOBreakdownsDefault } from '~/components/O/Breakdowns/Default/OBreakdownsDefault.types';
import type { TWebsiteConfig } from '~/typings/types/website-config.types';

// Schemas
import schema from '~/components/O/Breakdowns/Default/OBreakdownsDefault.schema';

defineOptions({
  name: 'OBreakdownsDefault',
});

const props = withDefaults(defineProps<IComponentProps<TOBreakdownsDefault>>(), {
  viewName: 'OBreakdownsDefault',
});

const websiteConfig = useState<TWebsiteConfig>('config');

const { getComponent } = useGetData<TOBreakdownsDefault>();
const { requireForm, formList } = useForms();

const data = ref<TOBreakdownsDefault>(getComponent(props).component);

useComponentSchemaValidator(schema, props);
</script>
